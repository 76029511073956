import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/Builder.vue"),
      },
      {
        path: "/notFound",
        name: "notFound",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
      },
      {
        path: "/CZEnergyMarketPriceReport",
        name: "CZEnergyMarketPriceReport",
        component: () =>
          import("@/views/CZDocumentExchange/CZEnergyMarketPriceReport.vue"),
        // import("@/views/crafted/widgets/Tables.vue"),
      },
      {
        path: "/crafted/pages/profile",
        name: "profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        children: [
          {
            path: "overview",
            name: "profile-overview",
            component: () =>
              import("@/views/crafted/pages/profile/Overview.vue"),
          },
          {
            path: "projects",
            name: "profile-projects",
            component: () =>
              import("@/views/crafted/pages/profile/Projects.vue"),
          },
          {
            path: "campaigns",
            name: "profile-campaigns",
            component: () =>
              import("@/views/crafted/pages/profile/Campaigns.vue"),
          },
          {
            path: "documents",
            name: "profile-documents",
            component: () =>
              import("@/views/crafted/pages/profile/Documents.vue"),
          },
          {
            path: "connections",
            name: "profile-connections",
            component: () =>
              import("@/views/crafted/pages/profile/Connections.vue"),
          },
          {
            path: "activity",
            name: "profile-activity",
            component: () =>
              import("@/views/crafted/pages/profile/Activity.vue"),
          },
        ],
      },
      {
        path: "/crafted/pages/wizards/horizontal",
        name: "horizontal-wizard",
        component: () => import("@/views/crafted/pages/wizards/Horizontal.vue"),
      },
      {
        path: "/crafted/pages/wizards/vertical",
        name: "vertical-wizard",
        component: () => import("@/views/crafted/pages/wizards/Vertical.vue"),
      },
      {
        path: "/crafted/account",
        name: "account",
        component: () => import("@/views/crafted/account/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/crafted/account/Overview.vue"),
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/crafted/account/Settings.vue"),
          },
        ],
      },
      {
        path: "/apps/customers/getting-started",
        name: "apps-customers-getting-started",
        component: () => import("@/views/apps/customers/GettingStarted.vue"),
      },
      {
        path: "/apps/customers/customers-listing",
        name: "apps-customers-listing",
        component: () => import("@/views/apps/customers/CustomersListing.vue"),
      },
      {
        path: "/apps/customers/customer-details",
        name: "apps-customers-details",
        component: () => import("@/views/apps/customers/CustomerDetails.vue"),
      },
      {
        path: "/apps/calendar",
        name: "apps-calendar",
        component: () => import("@/views/apps/Calendar.vue"),
      },
      {
        path: "/apps/chat/private-chat",
        name: "apps-private-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/group-chat",
        name: "apps-group-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/drawer-chat",
        name: "apps-drawer-chat",
        component: () => import("@/views/apps/chat/DrawerChat.vue"),
      },
      {
        path: "/crafted/modals/general/invite-friends",
        name: "modals-general-invite-friends",
        component: () =>
          import("@/views/crafted/modals/general/InviteFriends.vue"),
      },
      {
        path: "/crafted/modals/general/view-user",
        name: "modals-general-view-user",
        component: () => import("@/views/crafted/modals/general/ViewUsers.vue"),
      },
      {
        path: "/crafted/modals/general/upgrade-plan",
        name: "modals-general-upgrade-plan",
        component: () =>
          import("@/views/crafted/modals/general/UpgradePlan.vue"),
      },
      {
        path: "/crafted/modals/general/share-and-earn",
        name: "modals-general-share-and-earn",
        component: () =>
          import("@/views/crafted/modals/general/ShareAndEarn.vue"),
      },
      {
        path: "/crafted/modals/forms/new-target",
        name: "modals-forms-new-target",
        component: () => import("@/views/crafted/modals/forms/NewTarget.vue"),
      },
      {
        path: "/crafted/modals/forms/new-card",
        name: "modals-forms-new-card",
        component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
      },
      {
        path: "/crafted/modals/forms/new-address",
        name: "modals-forms-new-address",
        component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
      },
      {
        path: "/crafted/modals/forms/create-api-key",
        name: "modals-forms-create-api-key",
        component: () =>
          import("@/views/crafted/modals/forms/CreateApiKey.vue"),
      },
      {
        path: "/crafted/modals/wizards/two-factor-auth",
        name: "modals-wizards-two-factor-auth",
        component: () =>
          import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-app",
        name: "modals-wizards-create-app",
        component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-account",
        name: "modals-wizards-create-account",
        component: () =>
          import("@/views/crafted/modals/wizards/CreateAccount.vue"),
      },
      {
        path: "/crafted/widgets/lists",
        name: "widgets-list",
        component: () => import("@/views/crafted/widgets/Lists.vue"),
      },
      {
        path: "/crafted/widgets/statistics",
        name: "widgets-statistics",
        component: () => import("@/views/crafted/widgets/Statistics.vue"),
      },
      {
        path: "/crafted/widgets/charts",
        name: "widgets-charts",
        component: () => import("@/views/crafted/widgets/Charts.vue"),
      },
      {
        path: "/crafted/widgets/mixed",
        name: "widgets-mixed",
        component: () => import("@/views/crafted/widgets/Mixed.vue"),
      },
      {
        path: "/crafted/widgets/tables",
        name: "widgets-tables",
        component: () => import("@/views/crafted/widgets/Tables.vue"),
      },
      {
        path: "/crafted/widgets/feeds",
        name: "widgets-feeds",
        component: () => import("@/views/crafted/widgets/Feeds.vue"),
      },
      {
        path: "/MarketInsight",
        name: "MarketInsight",
        component: () => import("@/views/CZMarketInsight/CZMarketInsight.vue"),
        children: [
          {
            path: "GasPrices",
            name: "GasPrices",
            component: () => import("@/views/CZMarketInsight/CZGasPrices.vue"),
          },
          {
            path: "GasDemand",
            name: "GasDemand",
            component: () => import("@/views/CZMarketInsight/CZGasDemand.vue"),
            // component: () =>
            //   import("@/views/crafted/authentication/Error404.vue"),
          },
          {
            path: "ElectricityPrices",
            name: "ElectricityPrices",
            component: () =>
              import("@/views/CZMarketInsight/CZElectricityPrices.vue"),
            // component: () =>
            //   import("@/views/crafted/authentication/Error404.vue"),
          },
          {
            path: "ElectricityDemand",
            name: "ElectricityDemand",
            component: () =>
              import("@/views/CZMarketInsight/CZElectricityDemand.vue"),
            // component: () =>
            //   import("@/views/crafted/authentication/Error404.vue"),
          },
          {
            path: "UKElectricityGeneration",
            name: "UKElectricityGeneration",
            component: () =>
              import("@/views/CZMarketInsight/CZUKElectricityGeneration.vue"),
            // component: () =>
            //   import("@/views/crafted/authentication/Error404.vue"),
          },
          {
            path: "CO2Emmition",
            name: "CO2Emmition",
            component: () =>
              import("@/views/CZMarketInsight/CZCO2Emmition.vue"),
            // component: () =>
            //   import("@/views/crafted/authentication/Error404.vue"),
          },
          {
            path: "ExchangeRate",
            name: "ExchangeRate",
            component: () =>
              import("@/views/CZMarketInsight/CZExchangeRate.vue"),
            // component: () =>
            //   import("@/views/crafted/authentication/Error404.vue"),
          },
          {
            path: "CrudeOil",
            name: "CrudeOil",
            component: () => import("@/views/CZMarketInsight/CrudeOil.vue"),
            // component: () =>
            //   import("@/views/crafted/authentication/Error404.vue"),
          },
          {
            path: "Weather",
            name: "Weather",
            component: () => import("@/views/CZMarketInsight/CZWeather.vue"),
            // component: () =>
            //   import("@/views/crafted/authentication/Error404.vue"),
          },
        ],
      },
      {
        path: "/FlexibleProcurement",
        name: "FlexibleProcurement",
        component: () =>
          import("@/views/CZFlexibleProcurement/CZPositionReports.vue"),
        children: [
          {
            path: "PositionSummary",
            name: "PositionSummary",
            component: () =>
              import("@/views/CZFlexibleProcurement/CZpositionSummary.vue"),
          },
          {
            path: "PerformanceSummary",
            name: "PerformanceSummary",
            component: () =>
              import("@/views/CZFlexibleProcurement/CZPerformanceSummary.vue"),
          },
          {
            path: "OpenVolume",
            name: "OpenVolume",
            component: () =>
              import("@/views/CZFlexibleProcurement/CZOpenVolume.vue"),
          },
          {
            path: "ClosedVolume",
            name: "ClosedVolume",
            component: () =>
              import("@/views/CZFlexibleProcurement/CZClosedVolume.vue"),
          },
          {
            path: "SavingsVsBudget",
            name: "SavingsVsBudget",
            component: () =>
              import("@/views/CZFlexibleProcurement/CZSavingsVsBudget.vue"),
          },
          {
            path: "PositionReportDashboard",
            name: "PositionReportDashboard",
            component: () =>
              import(
                "@/views/CZFlexibleProcurement/CZPositionReportDashboard.vue"
              ),
          },
          {
            path: "PositionDataTable",
            name: "PositionDataTable",
            component: () =>
              import("@/views/CZFlexibleProcurement/CZPositionDataTable.vue"),
          },
        ],
      },
      {
        path: "/AssetRegisterSupplier",
        name: "AssetRegisterSupplier",
        component: () =>
          import("@/views/CZAssetRegister/CZAssetRegisterSupplier.vue"),

        children: [
          {
            path: "CZGraphs",
            name: "CZGraphs",
            component: () => import("@/views/CZAssetRegister/CZGraphs.vue"),
          },
          {
            path: "assetElectrcityList",
            name: "Asset Register List - Electricity",
            component: () =>
              import("@/views/CZAssetRegister/CZElectricityRegisterList.vue"),
          },
          {
            path: "assetGasList",
            name: "Asset Register List - Gas",
            component: () =>
              import("@/views/CZAssetRegister/CZGasRegisterList.vue"),
          },
        ],
      },

      {
        path: "/bureauAssetRegisterSupplier",
        name: "BureauAssetRegisterSupplier",
        component: () =>
          import(
            "@/views/CZBureauAssetRegister/CZAssetRegisterSupplierBureau.vue"
          ),
      },

      {
        path: "assetAdditionalRemovalElectric",
        name: "AssetAdditionalRemovalElectric",
        component: () =>
          import(
            "@/views/CZAssetAddRemove/CZAssetAdditionalRemovalElectric.vue"
          ),
      },
      {
        path: "assetAdditionalRemovalGas",
        name: "AssetAdditionalRemovalGas",
        component: () =>
          import("@/views/CZAssetAddRemove/CZAssetAdditionalRemovalGas.vue"),
      },
      {
        path: "/CZEnergyMarketPriceReport",
        name: "CZEnergyMarketPriceReport",
        component: () =>
          import("@/views/CZDocumentExchange/CZEnergyMarketPriceReport.vue"),
      },

      {
        path: "/meterType",
        name: "CZMeterType",
        component: () => import("@/views/CZMeterType/CZMeterType.vue"),
      },

      {
        path: "/historicConsumptionElectricity",
        name: "HistoricConsumptionEb",
        component: () =>
          import(
            "@/views/CZHistoricConsumption/CZHistoricConsumptionCo2EB.vue"
          ),
      },
      {
        path: "/historicConsumptionGas",
        name: "HistoricConsumptiongas",
        component: () =>
          import(
            "@/views/CZHistoricConsumption/CZHistoricConsumptionCo2Gas.vue"
          ),
      },

      {
        path: "/projectedConsumptionElectricity",
        name: "ProjectedConsumptionEb",
        component: () =>
          import(
            "@/views/CZProjectedConsumption/CZProjectedConsumptionCo2EB.vue"
          ),
      },
      {
        path: "/projectedConsumptionGas",
        name: "ProjectedConsumptiongas",
        component: () =>
          import(
            "@/views/CZProjectedConsumption/CZProjectedConsumptionCo2Gas.vue"
          ),
      },

      {
        path: "/lastAcutalReadElec",
        name: "LastAcutalReadElec",
        component: () =>
          import("@/views/CZLastMeterRead/CZLastAcutalMeterReadElec.vue"),
      },
      {
        path: "/lastAcutalReadGas",
        name: "LastAcutalReadGas",
        component: () =>
          import("@/views/CZLastMeterRead/CZLastAcutalMeterReadGas.vue"),
      },
      {
        path: "/lastBillDateElec",
        name: "LastBillDateElec",
        component: () =>
          import("@/views/CZLastBillDate/CZLastBillDateElec.vue"),
      },
      {
        path: "/lastBillDateGas",
        name: "LastBillDateGas",
        component: () => import("@/views/CZLastBillDate/CZLastBillDateGas.vue"),
      },

      {
        path: "/AccrualsForElectrictyNoSplit",
        name: "AccrualsForElectrictyNoSplit",
        component: () =>
          import("@/views/CZAccuralReport/CZAccrualsElectricity.vue"),
      },
      {
        path: "/AccrualsForGasNoSplit",
        name: "AccrualsForGasNoSplit",
        component: () => import("@/views/CZAccuralReport/CZAccrualsGas.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/EICAuth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/EICSignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
